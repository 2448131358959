import React, { Component } from 'react';
import { firebase } from '../firebase';
import { Form, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../common/LoadingScreen.js';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', loading: false, error: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        this.setState({loading: true, error: false});
        firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password).then().catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.errorMessage;
            console.log(errorCode, errorMessage);
            this.setState({loading: false, error: true});
        }.bind(this))
        event.preventDefault();
    }

    render() {
        if (this.state.loading) {
            return <LoadingScreen />
        }

        if (this.props.authUser) {
            return <Redirect to="/home" />
        }

        return (
            <Form error={this.state.error} onSubmit={this.handleSubmit}>
                <Form.Input value={this.state.username} onChange={this.handleChange} name="username" placeholder="E-mail adress" />
                <Form.Input value={this.state.password} onChange={this.handleChange} name="password" placeholder="Password" type="password" />
                <Message error content="Incorrect username/password!" />
                <br />
                <Form.Button inverted type='submit'>Login</Form.Button>
                <br />
                <Link className="tp-forgot-password-link" to="/forgotpassword">Forgot password?</Link>
            </Form>
        )
    }
}

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(LoginForm);