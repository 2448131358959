import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';

const LoadingScreen = (props) => (
  <Grid padded className="fullheight fullwidth loginpage" centered verticalAlign="middle">
    <Grid.Column width={5}>
      <Loader active inverted size="large" inline="centered" />
    </Grid.Column>
  </Grid>
)

export default LoadingScreen;