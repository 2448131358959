import firebase from 'firebase';
import 'firebase/firestore';

var app = firebase.initializeApp({
    apiKey: "AIzaSyBHw0tdlKGtvqo39L9_jmdsLl9kLoZ-7_Q",
    authDomain: "zenzuren-73878.firebaseapp.com",
    databaseURL: "https://zenzuren-73878.firebaseio.com",
    projectId: "zenzuren-73878",
    storageBucket: "zenzuren-73878.appspot.com",
    messagingSenderId: "218249310291"
});

var firestore = app.firestore();
app.timestamp = firebase.firestore.FieldValue.serverTimestamp();

var azureProvider = new firebase.auth.OAuthProvider('microsoft.com');
azureProvider.setCustomParameters({
    tenant: "96b5a307-f8a6-4463-93f3-8326ef71b4eb"
});
  
export { firestore };
export { app as firebase };
export {azureProvider};