import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import * as moment from 'moment';

const WEEKSINMENU = 5;

class WeekMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {offset: 0}

        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleClickLeft = this.handleClickLeft.bind(this);
        this.handleClickRight = this.handleClickRight.bind(this);        
    }

    handleItemClick(selected_week) {
        this.props.onWeekChange(selected_week);
    }

    handleClickLeft() {
        let newoffset = this.state.offset - 1;
        this.setState({offset: newoffset});
    }

    handleClickRight() {
        let newoffset = this.state.offset + 1;
        this.setState({offset: newoffset});
    }

    render() {
        const curweek = moment().startOf('isoWeek');
        const offset = this.state.offset;
        
        let lastweek = moment(curweek).subtract(1, 'week');
        let thisweek = moment(curweek);
        let nextweek = moment(curweek).add(1, 'week');

        let out = [];
        for (let w = WEEKSINMENU - 1; w >= -WEEKSINMENU; w--) {
            let m;
            if (w - offset > 0) {
                m = moment(curweek).subtract(w - offset, 'week');
            } else {
                m = moment(curweek).add(-(w - offset), 'week');
            }
            let name = "W" + m.isoWeek().toString();
            if (m.isSame(lastweek, 'day')) name = "Last week";
            if (m.isSame(thisweek, 'day')) name = "This week";
            if (m.isSame(nextweek, 'day')) name = "Next week";
            
            out.push({name: name, moment: m});
        }


        out = out.map(item => 
            <Menu.Item 
                key={item.moment.isoWeek()}
                moment={item.moment}
                name={item.name}
                active={this.props.selected_week.isSame(item.moment)}
                onClick={() => this.handleItemClick(item.moment)} />
        )

        let nritems = out.length + 2;
        
        return (
            <Menu pagination fluid widths={nritems}>
                <Menu.Item as='a' icon onClick={this.handleClickLeft}>
                    <Icon color="orange" name='chevron left' />
                </Menu.Item>
                {out}
                <Menu.Item as='a' icon onClick={this.handleClickRight}>
                    <Icon color="orange" name='chevron right' />
                </Menu.Item>
                
            </Menu>
        )
    }
}

export default WeekMenu;