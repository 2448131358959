import React from 'react';
import { Table } from 'semantic-ui-react';
import OverviewTableRow from './OverviewTableRow';

class OverviewTablePerUser extends React.Component {
    addhours(accumulator, a) {
        return accumulator + a;
    }

    gettotalstyle(total) {
        if (total === 0) return { backgroundColor: 'rgb(255,140,140)' };
        else if (total < 40) return { backgroundColor: 'rgb(240,240,240)' };
        return { backgroundColor: 'rgb(0,176,88)' };
    }

    GetName(record) {
        if (record) return record.name;
        return '';
    }

    GetTotalRow() {
        let total = this.props.total.hours.reduce(this.addhours);
        let totalstyle = this.gettotalstyle(total);

        return <OverviewTableRow
            key={this.props.total.key}
            hours={this.props.total.hours}
            employee={this.props.total.username}
            customer={""}
            project={""}
            description={total}
            dates={this.props.dates}
            style={totalstyle}
            rowspan={1}
        />
    }

    GetRow = (record) => {
        let total = this.props.total.hours.reduce(this.addhours);
        let totalstyle = this.gettotalstyle(total);

        return <OverviewTableRow
            key={record.id}
            hours={record.hours}
            username={this.props.total.username}
            project={this.GetName(record.projectinfo)}
            customer={this.GetName(record.customerinfo)}
            description={record.description}
            dates={this.props.dates}
            style={totalstyle}
            rowspan={1}
        />
    }

    render() {
        if (!this.props.dates) return null;

        return (
            <>
                <Table.Row><Table.Cell colSpan='9'></Table.Cell></Table.Row>
                {this.GetTotalRow()}
                {this.props.records.map(this.GetRow)}
            </>
        );


    }
}

export default OverviewTablePerUser