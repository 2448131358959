import React from "react";
import moment from "moment";
import { Icon, Button, Dropdown, Checkbox } from "semantic-ui-react";
import { connect } from 'react-redux';
import { getRecords, setWeekRecord, deleteRecord } from '../modules/Records';
import { uploadRecord, getWeekCode } from "./utils";
import { firestore } from "../firebase";

const NRWEEKSINDROPDOWN = 130;

const getWeekOptions = (prevWeek) => {
    const curweek = moment().startOf('isoWeek');
    const startweek = moment(curweek).subtract(NRWEEKSINDROPDOWN / 2, 'week');
    let lastweek = moment(curweek).subtract(1, 'week');
    let thisweek = moment(curweek);
    let nextweek = moment(curweek).add(1, 'week');

    let out = [];
    for (let w = 0; w < NRWEEKSINDROPDOWN; w++) {
        const m = moment(startweek).add(w, 'week');
        const weekCode = getWeekCode(m);

        let name = "W" + m.isoWeek().toString();
        if (m.isSame(lastweek, 'day')) name = "Last week";
        if (m.isSame(thisweek, 'day')) name = "This week";
        if (m.isSame(nextweek, 'day')) name = "Next week";
        if (m.isSame(prevWeek, 'day')) name = "Previous week";
        
        out.push({text: name, key: weekCode, value: weekCode });
    }

    return out;
}

const CopyFromWeek = ({ selected_week, records, authUser, getRecords, setWeekRecord, deleteRecord }) => {
    const prevWeek = moment(selected_week).subtract(1, 'week');
    const prevWeekCode = getWeekCode(prevWeek);
    const [week, setWeek] = React.useState(getWeekCode(prevWeek));
    const [keepCurrentRecords, setKeepCurrentRecords] = React.useState(true);

    let copyFromRecords = null;
    if (week in records) {
        copyFromRecords = records[week][authUser.uid];
    };

    let selWeekRecords = null;
    if (getWeekCode(selected_week) in records) {
        selWeekRecords = records[getWeekCode(selected_week)][authUser.uid];
    }

    React.useEffect(() => {
        setWeek(prevWeekCode);
    }, [prevWeekCode]);

    React.useEffect(() => {
        if (copyFromRecords && copyFromRecords.length > 0) return;
        getRecords(week, authUser.uid);
    }, [week]);

    const copyFromPreviousWeek = () => {
        if (!copyFromRecords) return;

        // Delete current records
        let newWeekRecords = [];
        if (selWeekRecords) {
            for (let i = 0; i < selWeekRecords.length; i++) {
                if (keepCurrentRecords) {
                    newWeekRecords.push(selWeekRecords[i]);
                } else {
                    deleteRecord(getWeekCode(selected_week), authUser.uid, selWeekRecords[i].id);
                }
            }
        }

        // Copy records from selected week
        for (let i = 0; i < copyFromRecords.length; i++) {
            // Check if project already in week
            if (keepCurrentRecords && selWeekRecords) {
                let found = false;
                for (let j = 0; j < selWeekRecords.length; j++) {
                    if (selWeekRecords[j].project !== copyFromRecords[i].project) continue;
                    found = true;
                    break;
                }
                if (found) continue;
            } 

            const newRecordRef = firestore.collection("records").doc();

            let newRecord = {
                ...copyFromRecords[i],
                id: newRecordRef.id
            };

            if (copyFromRecords[i].hours) {
                newRecord.hours = [...copyFromRecords[i].hours];
            }

            newWeekRecords.push(newRecord);
            uploadRecord(newRecord, authUser, getWeekCode(selected_week));
        }

        // Set in local state
        setWeekRecord(getWeekCode(selected_week), authUser.uid, newWeekRecords);
    }

    return (
        <div>
            <div style={{margin: "12px", marginLeft: 0}}>
            <Dropdown loading={!copyFromRecords} selection options={getWeekOptions(prevWeek)} value={week} onChange={(e, { value }) => setWeek(value)}/>
            <Checkbox style={{margin: "12px"}} label="Keep current records" checked={keepCurrentRecords} onChange={(e, data) => setKeepCurrentRecords(data.checked)} />
            </div>
            <div style={{margin: "12px", marginLeft: 0}}>
            <Button style={{marginLeft: 0}} loading={!copyFromRecords} onClick={copyFromPreviousWeek}><Icon name="copy" />Copy to this week</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {    
    return {
        records: state.records,
        authUser: state.auth.authUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecords: (weekcode, uid) => dispatch(getRecords(weekcode, uid)),
        setWeekRecord: (weekcode, uid, data) => dispatch(setWeekRecord(weekcode, uid, data)),
        deleteRecord: (weekcode, uid, recordId) => dispatch(deleteRecord(weekcode, uid, recordId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyFromWeek);