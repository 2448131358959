import React from 'react';
import ReactDOM from 'react-dom';
import {unregister} from './registerServiceWorker';
import { Provider } from "react-redux";

// App and store
import App from "./App";
import store from './store';

// CSS
import 'semantic-ui-css/semantic.min.css';
import 'react-dates/lib/css/_datepicker.css';
import './App.css';

import 'react-dates/initialize';

unregister();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
document.getElementById('root'));