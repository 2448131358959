import { firestore } from "../firebase";

const SET_AUTHUSER = "SET_AUTHUSER";

export const setAuthUser = (authUser) => (dispatch) => {
    if (authUser) {
        return firestore.collection('employees').doc(authUser.uid).get().then(async (doc) => {
            if (doc.exists) {
                const data = doc.data();
                authUser.projects = data.projects;
                authUser.isAdmin = data.isAdmin;
            } else {
                authUser.isAdmin = false;
                authUser.project = [];

                await firestore.collection('employees').doc(authUser.uid).set({
                    name: authUser.providerData[0].displayName,
                    projects: []
                });
            }
            
            authUser.name = authUser.providerData[0].displayName;

            dispatch({
                type: SET_AUTHUSER,
                payload: authUser
            })
        })
    } else {
        return dispatch({
            type: SET_AUTHUSER,
            payload: null
        });
    }
}

const initialState = {
    loading: true,
    authUser: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHUSER:
            return {
                loading: false,
                authUser: action.payload
            }
        default:
            return state;
    }
};