import React, { useEffect } from 'react';
import InputTable from './InputTable';
import * as moment from 'moment';
import WithLoading from './../common/WithLoading';
import { connect } from 'react-redux';

import { getRecords, addRecord, deleteRecord } from '../modules/Records';

const InputTableWithLoading = WithLoading(InputTable);

const InputTableContainer = ({records, items, addRecord, deleteRecord, getRecords, selected_week, authUser}) => {

    useEffect(() => {
        if (!records || records.length === 0) {
            getRecords(getWeekCode(), authUser.uid);
        }
    }, [selected_week])
    
    const getWeekCode = () => moment(selected_week).isoWeekday(1).format("GGGG_WW");

    const handleAddRecord = () => {
        addRecord(getWeekCode(), authUser.uid);
    }

    const handleDeleteRecord = (recordId) => {
        deleteRecord(getWeekCode(), authUser.uid, recordId);
    }

    const from = moment(selected_week).isoWeekday(1);
    let to = moment(from).add(1, 'week');

    let dates = []
    for (var d = moment(from); d.isBefore(to); d.add(1, 'days')) {
        dates.push(moment(d));
    } 

    let userprojects = [];
    if (authUser.projects) {
        userprojects = items.filter(project => {
            if (authUser.projects.includes(project.id)) return true;
            return false;
        })
    }

    return (
        <InputTableWithLoading
            loading={!records}
            userprojects={userprojects} 
            records={records} 
            dates={dates} 
            onDeleteRecord={handleDeleteRecord}
            onAddRecord={handleAddRecord}
        />
    )
}

const mapStateToProps = (state, ownProps) => {    
    const weekcode = moment(ownProps.selected_week).isoWeekday(1).format("GGGG_WW");
    
    let records = null;
    if (weekcode in state.records) {
        records = state.records[weekcode][state.auth.authUser.uid];
    };

    return {
        customers: state.customers,
        projects: state.projects,
        records: records,
        authUser: state.auth.authUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecords: (weekcode, uid) => dispatch(getRecords(weekcode, uid)),
        addRecord: (weekcode, uid) => dispatch(addRecord(weekcode, uid)),
        deleteRecord: (weekcode, uid, recordId) => dispatch(deleteRecord(weekcode, uid, recordId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputTableContainer);