import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { firebase } from '../firebase';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class LogoutMenuItem extends Component {
    constructor(props) {
        super(props);

        this.logoutUser = this.logoutUser.bind(this);
    }

    logoutUser(e) {
        firebase.auth().signOut().then(function () {
            // Signed out
        }).catch(function (error) {
            // Error signing out
            console.log(error);
        });
    }

    render() {
        return <Menu.Item header name='logout' onClick={this.logoutUser} />
    }
}

const Navbar = ({authUser}) => (
    <Menu fixed="top" color="orange" inverted>
        <Menu.Item header><Link to="/home">Zenz Urenregistratie</Link></Menu.Item>
        <Menu.Item><Link to="/export"><Icon name="download" />Export</Link></Menu.Item>
        {authUser.isAdmin && <Menu.Item><Link to="/overview">Overview</Link></Menu.Item>}
        <Menu.Menu position='right'>
            <Menu.Item><Link to="/c"><Icon name="settings" /> Customers and Projects</Link></Menu.Item>
            <Menu.Item><Link to="/user"><Icon name="user" /> {authUser.name && authUser.name}</Link></Menu.Item>
            <LogoutMenuItem />
        </Menu.Menu>
    </Menu>
)

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(Navbar);