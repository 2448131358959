import { firestore } from "../firebase";

const FETCH_RECORDS = "FETCH_RECORDS";
const ADD_RECORD = "ADD_RECORD";
const DELETE_RECORD = "DELETE_RECORD";
const CHANGE_RECORD = "CHANGE_RECORD";
const SETWEEKRECORD = "SETWEEKRECORD";

export const getRecords = (weekcode, uid) => async dispatch => {
    let query = firestore.collection('records');
    if (uid) query = query.where('uid', '==', uid);

    return query.where('week', '==', weekcode).get().then((snapshot) => {
        var records = [];
        snapshot.forEach(function (doc) {
            records.push({
                ...doc.data(),
                id: doc.id
            });
        });

        dispatch({
            type: FETCH_RECORDS,
            payload: {
                weekcode: weekcode,
                records: records,
                uid: uid
            }
        })
    });
}

export const setWeekRecord = (weekcode, uid, data) => ({
    type: SETWEEKRECORD,
    payload: {
        weekcode,
        uid,
        data
    }
})

export const addRecord = (weekcode, uid) => ({
    type: ADD_RECORD,
    payload: {
        weekcode,
        uid
    }
})

export const onRecordChange = (weekcode, uid, recordId, recordData) => {
    return {
        type: CHANGE_RECORD,
        payload: {
            weekcode, uid, recordId, recordData
        }
    }
}

export const deleteRecord = (weekcode, uid, recordId) => async dispatch => {
    return firestore.collection("records").doc(recordId).delete().then(() => {
        dispatch({
            type: DELETE_RECORD,
            payload: {
                recordId,
                weekcode,
                uid
            }
        })
    });
}

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_RECORDS: {
            const { weekcode, records, uid } = action.payload;

            // Split records per uid
            let recordsPerUser = {};
            if (!uid) {
                for (let i = 0; i < records.length; i++) {
                    let record = records[i];
                    if (!recordsPerUser[record.uid]) {
                        recordsPerUser[record.uid] = [];
                    }

                    recordsPerUser[record.uid].push(record);
                }
            } else {
                recordsPerUser[uid] = records;
            }

            return {
                ...state, [weekcode]: {
                    ...state[weekcode], ...recordsPerUser
                }
            };
        }
        case ADD_RECORD: {
            const { weekcode, uid } = action.payload;
            const newRecordRef = firestore.collection("records").doc();

            return {
                ...state, [weekcode]: {
                    ...state[weekcode], [uid]: [
                        ...state[weekcode][uid], { 
                            id: newRecordRef.id,
                            hours: Array(7).fill(0)
                        }
                    ]
                }
            };
        }
        case DELETE_RECORD: {
            const { weekcode, recordId, uid } = action.payload;

            return {
                ...state, [weekcode]: {
                    ...state[weekcode], [uid]: state[weekcode][uid].filter(record => recordId !== record.id)
                }
            };
        }
        case CHANGE_RECORD: {
            const { weekcode, recordId, uid, recordData } = action.payload;

            return {
                ...state, [weekcode]: {
                    ...state[weekcode], [uid]: state[weekcode][uid].map((record) => {
                        if (record.id !== recordId) return record;
                        return recordData
                    })
                }
            };
        }
        case SETWEEKRECORD: {
            const { weekcode, uid, data } = action.payload;
            
            return {
                ...state, [weekcode]: {
                    ...state[weekcode], [uid]: data
                }
            };
        }
        default:
            return state;
    }
};