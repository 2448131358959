import React from 'react';
import MainLayout from '../common/MainLayout';
import WithCustomersAndProjects from '../common/WithCustomersAndProjects';
import WithLoading from '../common/WithLoading';
import UserProjectTable from './UserProjectTable';
import {maincolumns} from '../globals';

const UserProjectTableWithLoading = WithLoading(UserProjectTable);
const UserContainer = WithCustomersAndProjects(UserProjectTableWithLoading);

const User = (props) => {
    return (
        <MainLayout columnWidths={maincolumns}>
            <UserContainer {...props} />
        </MainLayout>
    )
}

export default User;