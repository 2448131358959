import React from 'react';
import SingleFBInput from '../common/SingleFBInput';
import SettingsTable from './SettingsTable';
import WithLoading from '../common/WithLoading';
import { connect } from "react-redux";

const SettingsTableWithLoading = WithLoading(SettingsTable);

const Customers = ({ customers }) => (
    <div>
        <SingleFBInput name="customer" fbRef="customers" fbKey="name" idfromvalue />
        <SettingsTableWithLoading loading={!customers} items={customers} header="Customer" linkpath="/c/" />
    </div>
)

const mapStateToProps = (state) => {    
    return {
        customers: state.customers,
    };
};

export default connect(
    mapStateToProps,
    {}
)(Customers);