import React from 'react';
import MainLayout from '../common/MainLayout';
import WithCustomersAndProjects from '../common/WithCustomersAndProjects';
import WithLoading from '../common/WithLoading';
import {maincolumns} from '../globals';
import ExportForm from './ExportForm';
import {Header, Segment} from 'semantic-ui-react';

const ExportFormWithLoading = WithLoading(ExportForm);
const ExportFormWithCustomersAndProjects = WithCustomersAndProjects(ExportFormWithLoading);

const Export = (props) => {
    return (
        <MainLayout columnWidths={maincolumns}>
            <br />
            <Segment.Group>
                <Segment>
                    <Header as='h3' textAlign="center">
                        Export to Excel
                    </Header>
                </Segment>
                <Segment>
                    <ExportFormWithCustomersAndProjects {...props} />
                </Segment>
            </Segment.Group>
        </MainLayout>
    )
}

export default Export;
