import React from 'react'
import { firestore } from '../firebase';
import { Checkbox } from 'semantic-ui-react';

class ProjectEmployeeCheckbox extends React.Component {
    constructor(props) {
        super(props);

        let checked = false;
        if (this.props.projects) {
            if (this.props.projects.includes(this.props.projectid)) checked = true;
        }

        this.state = ({checked: checked});
        this.handleCheck = this.handleCheck.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let checked = false;
        if (nextProps.projects) {
            if (nextProps.projects.includes(nextProps.projectid)) checked = true;
        }

        return { checked: checked };
    }

    handleCheck(event, target) {
        this.setState({checked: target.checked});
        
        var value = [];
        if (this.props.projects) value = this.props.projects;

        var index = value.indexOf(this.props.projectid);
        if (index < 0) {
            if (target.checked) {
                value.push(this.props.projectid);
            }
        } else {
            if (!target.checked) {
                value.splice(index, 1);
            }
        }

        const data = {projects: value};
        firestore.collection("employees").doc(this.props.employeeid).update(data)
            .then(() => {
                console.log("successfully added/remove project to employee");
            }).catch(err => {
                console.log(err);
            })
    }

    render() {
        return (
            <Checkbox toggle checked={this.state.checked} onChange={this.handleCheck} />
        )
    }
}

export default ProjectEmployeeCheckbox;
