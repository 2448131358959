import React from 'react';
import MainLayout from '../common/MainLayout';
import OverviewTableContainer from './OverviewTableContainer';
import {homecolumns} from '../globals';
import WeekMenu from '../Home/WeekMenu';
import * as moment from 'moment';

class Overview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {selected_week: moment().startOf('isoWeek'), items: [], loading: true };        
        this.handleWeekChange = this.handleWeekChange.bind(this);
    }

    handleWeekChange(selected_week) {
        this.setState({selected_week: selected_week});
    }

    render() {
        return (
            <MainLayout columnWidths={homecolumns}>
                <WeekMenu  selected_week={this.state.selected_week} onWeekChange={this.handleWeekChange}/>
                <OverviewTableContainer selected_week={this.state.selected_week} />
            </MainLayout> 
            
        )
    }
}

export default Overview;