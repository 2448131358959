import React from 'react';
import { Grid } from 'semantic-ui-react';
import Navbar from '../common/Navbar';

const MainLayout = ({columnWidths, children}) => (
    <div className="homecontainer">
        <Navbar />
        <Grid padded centered verticalAlign="middle">
            <Grid.Row>
                <Grid.Column {...columnWidths}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
)

export default MainLayout;