import { connect } from "react-redux";

const mapStateToProps = (state) => {    
    let loading = !state.customers || !state.projects;
    
    let items = null;
    if (!loading) {
        items = [ ...state.projects ];
        
        for (let i = 0; i < items.length; i++) {
            let customer = state.customers.find(customer => customer.id === items[i].customerid);
            if (customer) items[i].customername = customer.name;
        }

        items.sort(function (a, b) {
            if (a.customername < b.customername) return -1;
            if (a.customername > b.customername) return 1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        })
    }

    return {
        loading,
        items
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
);