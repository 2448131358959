const maincolumns = {
    widescreen: 5,    
    largeScreen: 6,
    computer: 7,
    tablet: 10,
    mobile: 14
}

const homecolumns = {
    widescreen: 12,    
    largeScreen: 14,
    computer: 14,
    tablet: 15,
    mobile: 15
}

export {maincolumns, homecolumns}