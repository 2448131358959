import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const SettingsTable = (props) => {
    var out = (
        <Table.Row>
            <Table.Cell>
                <i>Nothing added</i>
            </Table.Cell>
        </Table.Row>
    )

    if (props.items.length > 0) {
        out = props.items.map(item =>
            <Table.Row key={item.id}>
                <Table.Cell selectable={props.linkpath !== undefined}>
                    {props.linkpath 
                        ? <Link to={props.linkpath+item.id}>{item.name}</Link>
                        : <span>{item.name}</span>
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    return (
        <Table unstackable>
            <Table.Header> 
                <Table.Row>
                    <Table.HeaderCell>{props.header}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {out}
            </Table.Body>
        </Table>
    )
}

export default SettingsTable;