import React from 'react';
import { Table } from 'semantic-ui-react';
import ProjectEmployeeCheckbox from '../common/ProjectEmployeeCheckbox';

const EmployeesTable = (props) => {
    const out = props.items.map(item =>
            <Table.Row key={item.id}>
                <Table.Cell>
                    {item.name}
                </Table.Cell>
                <Table.Cell collapsing>
                    <ProjectEmployeeCheckbox employeeid={item.id} {...item} {...props}/>
                </Table.Cell>
            </Table.Row>
        )

    return (
        <Table unstackable>
            <Table.Header> 
                <Table.Row>
                    <Table.HeaderCell>{props.header}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>                    
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {out}
            </Table.Body>
        </Table>
    )
}

export default EmployeesTable;