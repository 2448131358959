import React from 'react';
import LoginForm from './LoginForm';
import LoginLayout from '../common/LoginLayout';
import LoginAzure from "./LoginAzure";

const Login = (props) => (
    <LoginLayout>
        <LoginAzure {...props} />
    </LoginLayout>
)

export default Login;