import React from 'react';
import SingleFBInput from '../common/SingleFBInput';
import SettingsTable from './SettingsTable';
import { connect } from "react-redux";

class Projects extends React.Component {
    render() {
        var header = "Projects";
        if (this.props.customer) header += " - " + this.props.customer.name;

        const extraData = {
            customerid: this.props.customerid
        }

        return (
            <div>
                <SingleFBInput name="project" fbRef="projects" fbKey="name" extraData={extraData}/>                 
                <SettingsTable 
                    items={this.props.projects}
                    header={header}
                    linkpath={"/c/" + this.props.customerid + "/"} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        customer: state.customers[ownProps.customerid],
        projects: state.projects.filter((project) => project.customerid === ownProps.customerid)
    };
};

export default connect(
    mapStateToProps,
    {}
)(Projects);