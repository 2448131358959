import React from 'react';
import MainLayout from '../common/MainLayout';
import HomeContainer from './HomeContainer';
import {homecolumns} from '../globals';

const Home = (props) => (
  <MainLayout columnWidths={homecolumns}>
    <HomeContainer {...props}/>
  </MainLayout>
)

export default Home;