import React from 'react';
import firebase from "firebase";
import { azureProvider } from '../firebase';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const LoginAzure = ({ authUser }) => {
    React.useEffect(() => {
        firebase.auth().getRedirectResult()
            .then((result) => {
                if (result && result.user) {
                    // var credential = result.credential;
                    // console.log(credential);
                } else {
                    firebase.auth().signInWithRedirect(azureProvider);
                }
            })
            .catch((error) => {
                // Handle error.
                console.log(error);
            });
    }, [authUser]);

     if (authUser)  return <Redirect to="/home" />

    return (
        <div />
    )
}

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(LoginAzure);