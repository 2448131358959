import React, { useEffect } from 'react';
import OverviewTable from './OverviewTable';
import * as moment from 'moment';
import WithLoading from './../common/WithLoading';
import { getRecords } from './../modules/Records';
import { connect } from "react-redux";

const OverviewTableWithLoading = WithLoading(OverviewTable);

const OverviewTableContainer = (props) => {
    
    const getWeekCode = () => moment(props.selected_week).isoWeekday(1).format("GGGG_WW");
    useEffect(() => {
        // Only get if no records, or only records by this user
        if (!props.records || Object.keys(props.records).length <= 1) {
            props.getRecords(getWeekCode(), null);
        }
    }, [props.selected_week])
    
    let loading = false;
    if (!props.employees) loading = true;
    if (!props.projects) loading = true;
    if (!props.records) loading = true;

    let aggperuser = {};
    
    if (!loading) {
        for (let e = 0; e < props.employees.length; e++) {
            aggperuser[props.employees[e].id] = {
                hours: Array(7).fill(0), 
                username: props.employees[e].name, 
                uid: props.employees[e].id
            };
        }

        for (var uid in props.records) {
            for (let rec = 0; rec < props.records[uid].length; rec++) {
                const record = props.records[uid][rec];
                for (let h = 0; h < 7; h++) {
                    aggperuser[uid].hours[h] += record.hours[h];
                }

                record.projectinfo = props.projects.find(project => project.id === record.project);
                if (record.projectinfo) {
                    record.customerinfo = props.customers.find(customer => customer.id === record.projectinfo.customerid);
                }
            }
        }
    }
        
    const from = moment(props.selected_week).isoWeekday(1);
    let to = moment(from).add(1, 'week');

    let dates = []
    for (var d = moment(from); d.isBefore(to); d.add(1, 'days')) {
        dates.push(moment(d));
    } 

    return (
        <OverviewTableWithLoading
            loading={loading} 
            aggperuser={aggperuser} 
            records={props.records}
            dates={dates} 
        />
    )
}

const mapStateToProps = (state, ownProps) => {    
    const weekcode = moment(ownProps.selected_week).isoWeekday(1).format("GGGG_WW");
    
    return {
        customers: state.customers,
        projects: state.projects,
        records: state.records[weekcode],
        employees: state.employees,
        authUser: state.auth.authUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecords: (weekcode, uid) => dispatch(getRecords(weekcode, uid))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverviewTableContainer);