import * as moment from 'moment';
import { firestore } from '../firebase';
import * as fileDownload from 'js-file-download';

export default function ExportToExcel(from, to, projects, allEmployees, callback, filterEmployee, filterCustomer, filterProject) {
    from.hour(0).minute(0);
    to.hour(23).minute(59);

    let recordQuery = firestore.collection("records")
        .where('week', '>=', from.format("GGGG_WW"))
        .where('week', '<=', to.format("GGGG_WW"));
    
    if (filterEmployee) {
        recordQuery = recordQuery.where('uid', '==', filterEmployee);
    }

    if (filterProject) {
        recordQuery = recordQuery.where('project', '==', filterProject);
    }
    
    let records = recordQuery.get().then((recordssnapshot) => {
        if (recordssnapshot.empty) {
            callback();
            return;
        }

        let employeenames = {}
        for (let i = 0; i < allEmployees.length; i++) {
            employeenames[allEmployees[i].id] = allEmployees[i].name;
        }
        
        let rows = [];
        recordssnapshot.forEach(function(doc) {
            let data = doc.data();
            
            let startdate = moment(data.week, "GGGG_WW");
            
            let project = projects.find(project => project.id === data.project);
            if (typeof project === "undefined") {
                project = {name: "UNKNOWN", customername: "UNKNOWN"};
            }

            if (filterCustomer) {
                if (project.customerid !== filterCustomer) return;
            }

            let description = "";
            if (data.description) description = data.description;
            
            for (let d = 0; d < data.hours.length; d++) {
                if (data.hours[d] === 0) continue;
                let day = moment(startdate).add(d, 'days');
                if (day.isBefore(from)) continue;
                if (day.isAfter(to)) continue;

                let row = [
                    employeenames[data.uid],
                    project.customername,
                    project.name,
                    day.format('DD/MM/YYYY'),
                    data.hours[d],
                    description
                ];

                rows.push(row);
            }
        })

        rows.sort(function(a, b) {
            let amoment = moment(a[3], "DD/MM/YYYY");
            let bmoment = moment(b[3], "DD/MM/YYYY");

            if (amoment.isBefore(bmoment)) return -1;
            if (amoment.isAfter(bmoment)) return 1;
            return 0;
        })

        let header = ["Persoon", "Klant","Project","Datum","Uren","Omschrijving"];
        let csvContent = header.join(";") + "\r\n";
        rows.forEach(function(rowArray) {
            let row = rowArray.join(';');
            csvContent += row + "\r\n";
        })

        fileDownload(csvContent, "ZenzUren_Export.csv");

        callback();
    }).catch(function(error) {
        console.log(error);
    })
} 