import React from 'react';
import LoginForm from './LoginForm';
import LoginLayout from '../common/LoginLayout';

const Login = (props) => (
    <LoginLayout>
        <LoginForm {...props} />
    </LoginLayout>
)

export default Login;