import React from 'react';
import MainLayout from '../common/MainLayout';
import Customers from './Customers';
import Projects from './Projects';
import Employees from  './Employees';
import {maincolumns} from '../globals';

const Settings = (props) => {
    var comp = null;
    if (!props.match.params.customerid) {
        comp = <Customers {...props} />
    } else if (!props.match.params.projectid) {
        comp = <Projects  {...props.match.params} {...props} />     
    } else {
        comp = <Employees {...props.match.params} {...props} />
    }

    return (
        <MainLayout columnWidths={maincolumns}>
            {comp}
        </MainLayout>
    )
}

export default Settings;