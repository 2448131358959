import React from 'react';
import { connect } from "react-redux";
import { Dropdown, Input, Table } from 'semantic-ui-react';
import { onRecordChange } from '../modules/Records';
import { uploadRecord, getWeekCode } from './utils';

class InputTableRow extends React.Component {
    timer = null;

    uploadState = () => {
        uploadRecord(this.props.record, this.props.authUser, getWeekCode(this.props.dates[0]));
    }

    handleHoursChange = (isoWeekday, value) => {
        clearTimeout(this.timer);
        
        if (this.props.record.hours[isoWeekday - 1] === value) return;
    
        let recordData = {
            ...this.props.record,
        }
        recordData.hours[isoWeekday - 1] = value;

        this.props.onRecordChange(getWeekCode(this.props.dates[0]), this.props.authUser.uid, this.props.record.id, recordData);
        this.timer = setTimeout(this.uploadState, 1000);
    }

    handleChange = (event, target) => {
        clearTimeout(this.timer);

        const value = target.value;
        const name = target.name;
        if (this.props.record[name] === value) return;
    
        let recordData = {
            ...this.props.record,
            [name]: value
        };
        
        this.props.onRecordChange(getWeekCode(this.props.dates[0]), this.props.authUser.uid, this.props.record.id, recordData);
        this.timer = setTimeout(this.uploadState, 1000);
    }

    render() {
        if (!this.props.dates) return null;
        
        let options = [];
        options = this.props.userprojects.map(project => {
            return {
                text: project.customername + " - " + project.name,
                key: project.id,
                value: project.id
            }
        })

        const inputcells = this.props.dates.map(date => {
            const value = this.props.record.hours[date.isoWeekday() - 1];
            return (
                <Table.Cell key={date.isoWeekday()} >
                    <Input 
                        fluid 
                        type="number" 
                        step="0.5" 
                        transparent 
                        placeholder="0"
                        value={value !== 0 ? value : ""}
                        onChange={(event, target) => this.handleHoursChange(date.isoWeekday(), target.value)} 
                    />
                </Table.Cell>
            )
        })

        return (
            <Table.Row>
                <Table.Cell>
                    <Dropdown 
                        fluid
                        placeholder="Select project"
                        basic
                        search
                        selection
                        options={options}
                        value={this.props.record.project}
                        onChange={this.handleChange}
                        name="project"
                    />
                </Table.Cell>              
                {inputcells}
                <Table.Cell>
                    <Input 
                        fluid
                        transparent
                        placeholder="Description..."
                        value={this.props.record.description}                        
                        onChange={this.handleChange}
                        name="description"
                    />
                </Table.Cell>                                      
                <Table.Cell 
                    className="mousehover"
                    collapsing icon="delete"
                    onClick={() => this.props.onDeleteRecord(this.props.record.id)}
                />                    
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

const mapDispatchToProps = (dispatch) => ({
    onRecordChange: (weekcode, uid, recordId, recordData) => dispatch(onRecordChange(weekcode, uid, recordId, recordData))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputTableRow);