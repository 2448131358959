import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';

function WithLoading(WrappedComponent) {
    return function WithLoadingComponent({ loading, ...props }) {
        if (!loading) return <WrappedComponent {...props} />; 
        
        return (
            <Grid>
                <Grid.Row>
                </Grid.Row>
                <Grid.Row>
                    <Loader active inline="centered" />
                </Grid.Row>
            </Grid>
        )
    }   
}

export default WithLoading;