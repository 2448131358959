import React from 'react';
import WithCustomersAndProjects from '../common/WithCustomersAndProjects';
import WithLoading from '../common/WithLoading';
import InputTableContainer from './InputTableContainer';
import WeekMenu from './WeekMenu';
import * as moment from 'moment';
import CopyFromPrevWeek from './CopyFromPrevWeek';

const InputTableContainerWithLoading = WithLoading(InputTableContainer);
const InputTableContainerWithProjects = WithCustomersAndProjects(InputTableContainerWithLoading);

class HomeContainer extends React.Component {
    state = {
        selected_week: moment().startOf('isoWeek')
    };

    handleWeekChange= (selected_week) => {
        this.setState({
            selected_week: selected_week
        });
    }

    render() {
        return (
            <div>
                <WeekMenu                         selected_week={this.state.selected_week} onWeekChange={this.handleWeekChange}/>
                <InputTableContainerWithProjects  selected_week={this.state.selected_week} />
                <CopyFromPrevWeek                 selected_week={this.state.selected_week} />
            </div>
        )
    }
}

export default HomeContainer