import React, { Component } from 'react';
import { firebase } from '../firebase';
import { Form } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {username: '', emailsent: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        firebase.auth().sendPasswordResetEmail(this.state.username)
        .then(function() {
            // Password email sent
            this.setState({emailsent: true});
        }.bind(this))
        .catch(function(error) {
            // Error
            var errorCode = error.code;
            var errorMessage = error.errorMessage;
            console.log(errorCode, errorMessage);
        });
        event.preventDefault();
    }

    render() {
        if (this.props.authUser || this.state.emailsent === true) {
            return <Redirect to="/home" />
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Input value={this.state.username} onChange={this.handleChange} name="username" placeholder="E-mail adress" />
                <Form.Button inverted type='submit'>Submit</Form.Button>
            </Form>
        )    
    }
}

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(ForgotPasswordForm);