import React from 'react';
import { Grid, Form, Loader, Icon, Dropdown } from 'semantic-ui-react';
import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';
import ExportToExcel from '../common/ExportToExcel';
import fetchEmployees from '../modules/Employees';
import { connect } from "react-redux";

class ExportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            startDate: null,
            endDate: null,
            focusedInput: null,
            
            employee: (props.authUser.isAdmin ? null : props.authUser.uid),
            customer: null,
            project: null
        };
    }

    getEmployeesDropdownOptions = () => {
        let options = [];
        for (let i = 0; i < this.props.employees.length; i++) {
            options.push({
                key: this.props.employees[i].id,
                value: this.props.employees[i].id,
                text: this.props.employees[i].name
            })
        }
        return options;
    }

    getCustomersDropdownOptions = () => {
        let customers = {};

        for (let i = 0; i < this.props.items.length; i++) {
            customers[this.props.items[i].customerid] = this.props.items[i].customername
        }

        return Object.keys(customers).map((customerid) => ({
            key: customerid,
            value: customerid,
            text: customers[customerid]
        }))
    }

    getProjectsDropdownOptions = () => {
        let items = this.props.items;

        let prepend = true;
        if (this.state.customer) {
            prepend = false;
            items = items.filter(project => project.customerid === this.state.customer);
        }

        return items.map((project) => ({
            key: project.id,
            value: project.id,
            text: (prepend ? (project.customername + " - ") : "") + project.name
        }))
    }

    _handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }


    handleSubmit = () => {
        const { startDate, endDate, employee, customer, project } = this.state;
        if (!startDate || !endDate) return;
        this.setState({ loading: true });
        ExportToExcel(startDate, endDate, this.props.items, this.props.employees, this.doneExporting, employee, customer, project);
    }

    doneExporting = () => {
        this.setState({ loading: false });
    }

    initialVisibleMonth() {
        return moment().subtract(1, 'months');
    }

    render() {
        if (!this.props.employees || this.state.loading) {
            return <Loader active inline="centered" />;
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Grid padded>
                    <Grid.Row>
                        <Form.Field style={{ margin: 0 }}>
                            <label>Employee</label>
                        </Form.Field>
                        <Dropdown
                            clearable
                            style={{ marginBottom: "20px" }}
                            placeholder="Select employee"
                            fluid
                            selection
                            options={this.getEmployeesDropdownOptions()}
                            value={this.state.employee}
                            disabled={!this.props.authUser.isAdmin}
                            name="employee"
                            onChange={this._handleChange}
                        />

                        <Form.Field style={{ margin: 0 }}>
                            <label>Customer</label>
                        </Form.Field>
                        <Dropdown
                            clearable
                            style={{ marginBottom: "20px" }}
                            placeholder="Select customer"
                            fluid
                            selection
                            options={this.getCustomersDropdownOptions()}
                            value={this.state.customer}
                            name="customer"
                            onChange={this._handleChange}
                        />

                        <Form.Field style={{ margin: 0 }}>
                            <label>Project</label>
                        </Form.Field>
                        <Dropdown
                            clearable
                            style={{ marginBottom: "20px" }}
                            placeholder="Select project"
                            fluid
                            selection
                            options={this.getProjectsDropdownOptions()}
                            value={this.state.project}
                            name="project"
                            onChange={this._handleChange}
                        />

                        <Form.Field style={{ width:"100%", margin: 0 }}>
                            <label>Period</label>
                        </Form.Field>
                        <br />
                        <DateRangePicker
                            displayFormat="DD-MM-YYYY"
                            isOutsideRange={() => false}
                            initialVisibleMonth={this.initialVisibleMonth}
                            noBorder
                            required
                            block
                            hideKeyboardShortcutsPanel
                            startDate={this.state.startDate}
                            startDateId="startdateid"
                            endDate={this.state.endDate}
                            endDateId="enddateid"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                        />
                    </Grid.Row>
                    <Grid.Row style={{ display: "block", padding: "10px", borderTop: "solid 1px lightgrey" }}>
                        <Form.Button floated="right" type='submit'><Icon name="download" />Export</Form.Button>
                    </Grid.Row>
                </Grid>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {    
    return {
        employees: state.employees,
        authUser: state.auth.authUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEmployees: dispatch(fetchEmployees)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportForm);