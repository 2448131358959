import React from 'react';
import { Table } from 'semantic-ui-react';

class OverviewTableRow extends React.Component {
    GetHours(hours) {
        if (hours > 0) return hours;
        return '';
    }

    render() {
        if (!this.props.dates) return null;

        const Overviewcells = this.props.dates.map(date => {
            return (
                <Table.Cell key={date.isoWeekday()} >
                    {this.GetHours(this.props.hours[date.isoWeekday() - 1])}
                </Table.Cell>
            )
        })

        return (
            <Table.Row style={this.props.style}>
                <Table.Cell>
                    {this.props.employee}
                </Table.Cell>
                <Table.Cell>
                    {this.props.customer}
                </Table.Cell>
                <Table.Cell>
                    {this.props.project}
                </Table.Cell>
                {Overviewcells}
                <Table.Cell>
                    {this.props.description}
                </Table.Cell>
            </Table.Row>
        )
    }
}

export default OverviewTableRow