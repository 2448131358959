import { combineReducers } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import employees from "./modules/Employees";
import customers from "./modules/Customers";
import projects from "./modules/Projects";
import records from "./modules/Records";
import auth from "./modules/Auth";

const rootReducer = combineReducers({
    employees,
    customers,
    projects,
    records,
    auth
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(reduxThunk)
));