import React from 'react';
import EmployeesTable from './EmployeesTable';
import { connect } from "react-redux";

class Employees extends React.Component {
    render() {
        if (!this.props.customer) return null;
        if (!this.props.project) return null;
        
        var header = "";
        header += this.props.customer.name;
        header += " - " + this.props.project.name;

        return (
            <EmployeesTable items={this.props.employees} header={header} {...this.props}/>
        )
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        employees: state.employees,
        customer: state.customers.find(customer => ownProps.customerid === customer.id),
        project: state.projects.find(project => ownProps.projectid === project.id)
    };
};

export default connect(
    mapStateToProps,
    {}
)(Employees);