const FETCH_PROJECTS = "FETCH_PROJECTS";

export const onProjectData = (data) => ({
    type: FETCH_PROJECTS,
    payload: data
});

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_PROJECTS:
            return action.payload;
        default:
            return state;
    }
};