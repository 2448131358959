import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import InputTableRow from './InputTableRow';

const InputTable = (props) => {
    const headerdatecells = props.dates.map(date => 
        <Table.HeaderCell key={date.isoWeekday()}>{date.format("ddd DD/MM")}</Table.HeaderCell>
    );

    let totalHours = Array(7).fill(0);
    let tablerows = [];
    for (let i = 0; i < props.records.length; i++) {
        const record = props.records[i];

        for (let h = 0; h < 7; h++) {
            totalHours[h] += Number(record.hours[h]);
        }

        tablerows.push(
            <InputTableRow 
                onDeleteRecord={props.onDeleteRecord} 
                key={record.id} 
                record={record} 
                userprojects={props.userprojects} 
                dates={props.dates} 
            />
        );
    };

    let totalRow = (
        <Table.Row>
            <Table.HeaderCell className="totalRow">
                Total
            </Table.HeaderCell>
            {totalHours.map((total, idx) => (
                <Table.HeaderCell key={idx} className="totalRow">
                    {total}
                </Table.HeaderCell>
            ))}
            <Table.HeaderCell colSpan="2" className="totalRow">
                {totalHours.reduce((acc, a) => acc + Number(a))} hours
            </Table.HeaderCell>
        </Table.Row>
    )

    return (
        <Table celled unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}>Project</Table.HeaderCell>              
                    {headerdatecells}
                    <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                    <Table.HeaderCell collapsing></Table.HeaderCell>                    
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tablerows}     
            </Table.Body>
            <Table.Footer fullWidth>
                {totalRow}
                <Table.Row>
                    <Table.HeaderCell colSpan="10" textAlign="center">
                        <Button icon circular size="tiny" onClick={props.onAddRecord}>
                            <Icon name='add' />
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}

export default InputTable