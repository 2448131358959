import React from 'react';
import { Table } from 'semantic-ui-react';
import ProjectEmployeeCheckbox from '../common/ProjectEmployeeCheckbox';
import { connect } from "react-redux";

const UserProjectTable = (props) => {
    const out = props.items.map(item =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    {item.customername}
                </Table.Cell>
                <Table.Cell>
                    {item.name}
                </Table.Cell>
                <Table.Cell collapsing>
                    <ProjectEmployeeCheckbox 
                        projects={props.authUser.projects}
                        projectid={item.id}
                        employeeid={props.authUser.uid}
                        {...item} {...props}/>
                </Table.Cell>
            </Table.Row>
        )

    return (
        <Table unstackable>
            <Table.Header> 
                <Table.Row>
                    <Table.HeaderCell>Client</Table.HeaderCell>
                    <Table.HeaderCell>Project</Table.HeaderCell>                    
                    <Table.HeaderCell></Table.HeaderCell>                    
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {out}
            </Table.Body>
        </Table>
    )
}

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(UserProjectTable);