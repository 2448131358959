const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";

export const onCustomerData = (data) => ({
    type: FETCH_CUSTOMERS,
    payload: data
});

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS:
            return action.payload;
        default:
            return state;
    }
};