import { firebase } from '../firebase';
import moment from "moment";

export const getWeekCode = (week) => moment(week).isoWeekday(1).format("GGGG_WW");

export const uploadRecord = (record, authUser, weekCode) => 
{
    if (!record) return;
    if (!record.id) return;

    let data = {};

    let hoursdata = [];
    for (let d = 0; d < record.hours.length; d++){
        if (record.hours[d] === "") {
            hoursdata.push(0);
            continue;
        }

        hoursdata.push(Number(record.hours[d]));
    }

    data.hours = hoursdata;
    if (record.project && record.project !== "") data.project = record.project;
    if (record.description && record.description !== "") data.description = record.description;
    data.uid = authUser.uid;
    data.timestamp = firebase.timestamp;
    data.week = weekCode;

    firebase.firestore().collection("records").doc(record.id).set(data, {merge: true}).then(() => {
        console.log("Succesfully uploaded record to " + record.id);
    }).catch(err => {
        console.log(err);
    })
}