import React from 'react';
import { Table } from 'semantic-ui-react';
import OverviewTablePerUser from './OverviewTablePerUser';

const OverviewTable = (props) => {
    let tableperuser = Object.keys(props.aggperuser).map(key =>
        <OverviewTablePerUser
            key={key}
            total={props.aggperuser[key]}
            dates={props.dates}
            records={(key in props.records ? props.records[key] : [])}
        />
    );

    const headerdatecells = props.dates.map(date =>
        <Table.HeaderCell key={date.isoWeekday()}>{date.format("ddd DD/MM")}</Table.HeaderCell>
    );

    return (
        <Table celled unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}>Employee</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Customer</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Project</Table.HeaderCell>
                    {headerdatecells}
                    <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tableperuser}
            </Table.Body>
        </Table>
    )
}

export default OverviewTable