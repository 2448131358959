import { firestore } from "../firebase";

export const subscribeTo = (collection, orderBy, dispatchAction) => {
    return firestore.collection(collection).orderBy(orderBy).onSnapshot((snapshot) => {
        var data = [];
        snapshot.forEach(function(doc) {
            data.push({
                ...doc.data(),
                id: doc.id
            });
        });
        dispatchAction(data);
    });
};