import React from 'react';
import { Form } from 'semantic-ui-react';
import { firestore } from '../firebase';

class SingleFBInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loading: false, value: "" };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);        
    }

    handleSubmit(event) {
        this.setState({ loading: true });

        let id = null;
        if (this.props.idfromvalue) {
            id = this.state.value.toLowerCase();
            id = id.split(' ').join('');
        } else {
            id = firestore.collection(this.props.fbRef).doc().id;
        }

        var data = {};
        if (this.props.extraData) data = this.props.extraData;
        data[this.props.fbKey] = this.state.value;

        firestore.collection(this.props.fbRef).doc(id).set(data, {merge: true})
            .then(function() {
                console.log("Succesfully uploaded to " + this.props.fbRef);
                console.log(this.props.fbKey + ": " + this.state.value);
                this.setState({value: "", loading: false});
            }.bind(this)).catch(function(err) {
                console.log("Error uploading to " + this.props.fbRef);
                console.log(this.props.fbKey + ": " + this.state.value);
                console.log(err);
                this.setState({value: "", loading: false});        
            }.bind(this));
    }

    componentWillReceiveProps(newprops) {
        if (newprops === this.props) return;

        this.setState({value: ""});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Input 
                    loading={this.state.loading}
                    placeholder={"Add new " + this.props.name}
                    name="value"
                    value={this.state.value}
                    onChange={this.handleChange} />                    
            </Form>
        )
    }
}

export default SingleFBInput;