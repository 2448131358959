import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import {maincolumns} from '../globals';

const LoginLayout = ({children}) => (
    <Grid padded centered className="fullwidth fullheight loginpage" verticalAlign="middle">
        <Grid.Column {...maincolumns} textAlign="center">
            <Grid.Row>
                <Grid centered>
                    <Grid.Column width={10}>
                        <Image src="/logo.png" />
                        <br /><br />
                    </Grid.Column>
                </Grid>
            </Grid.Row>
            <Grid.Row>
                {children}
            </Grid.Row>
        </Grid.Column>
    </Grid>
)

export default LoginLayout;
