import React, { useState, useEffect } from 'react';
import Login from './Login/Login';
import LoginOld from './Login/LoginOld';
import Home from './Home/Home';
import Overview from './Overview/Overview';
import User from './User/User';
import LoadingScreen from './common/LoadingScreen';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Export from './Export/Export';
import Settings from './Settings/Settings';

import OnlyLoggedOutRoute from './common/OnlyLoggedOutRoute';
import PrivateRoute from './common/PrivateRoute';

import { firebase, azureProvider } from './firebase';
import { connect } from 'react-redux';

import { setAuthUser } from './modules/Auth';
import { onCustomerData } from './modules/Customers';
import { onProjectData } from './modules/Projects';
import { onEmployeeData } from './modules/Employees';
import { subscribeTo } from './common/SubscribeTo';

import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';

const isLinkedWithAzure = (authUser) => {
    if (!authUser) return false;

    for (let p = 0; p < authUser.providerData.length; p++) {
        if (authUser.providerData[p].providerId === "microsoft.com") return true;
    }

    return false;
}

const unlinkWithPassword = (authUser) => {
    if (!isLinkedWithAzure(authUser)) return;

    authUser.unlink("password").then(() => {
        // Auth provider unlinked from account
        // ...
    }).catch((error) => {
        // An error happened
        // ...
        console.log(error);
    });
}

const App = ({ setAuthUser, authUser, onCustomerData, onProjectData, onEmployeeData, loading }) => {
    useEffect(() => {
        const unsubscribeAuth = authUser ? () => {} : firebase.auth().onAuthStateChanged((user) => {
            if (user && !isLinkedWithAzure(user)) {
                user.linkWithRedirect(azureProvider)
                    .then((result) => {
                        // Accounts successfully linked.
                        // var credential = result.credential;
                        // var user = result.user;
                        // ...
                    }).catch((error) => {
                        // Handle Errors here.
                        // ...
                        console.log(error);
                    });
            } else {
                unlinkWithPassword(user);
                setAuthUser(user);
            }
        });

        const unsubscribeCustomers = authUser ? subscribeTo("customers", "name", onCustomerData) : () => {};
        const unsubscribeProjects = authUser ? subscribeTo("projects", "name", onProjectData) : () => {};
        const unsubscribeEmployees = authUser ? subscribeTo("employees", "name", onEmployeeData) : () => {};

        return () => {
            unsubscribeAuth();
            unsubscribeCustomers();
            unsubscribeProjects();
            unsubscribeEmployees();
        }
    }, [authUser]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Router>
            <Switch>
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/user" component={User} />
                <PrivateRoute path="/c/:customerid?/:projectid?" component={Settings} />
                <PrivateRoute path="/export" component={Export} />
                <PrivateRoute path="/overview" component={Overview} />
                <OnlyLoggedOutRoute path="/forgotpassword" component={ForgotPassword} />
                <OnlyLoggedOutRoute path="/login" component={Login} />
                <OnlyLoggedOutRoute path="/loginold" component={LoginOld} />
                <Redirect from="*" to="/home" />
            </Switch>
        </Router>
    );
}

const mapStateToProps = state => ({
    loading: state.auth.loading,
    authUser: state.auth.authUser
})

const mapDispatchToProps = dispatch => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)),
    onCustomerData: (data) => dispatch(onCustomerData(data)),
    onProjectData: (data) => dispatch(onProjectData(data)),
    onEmployeeData: (data) => dispatch(onEmployeeData(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);